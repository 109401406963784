import { useProjects } from 'contexts/projectsContext';
import React, { useEffect, useState } from 'react';
import { HeaderTable, Table } from 'UI/organism/Dashboards/Charts/Ranking';
import ChartContainer from 'UI/Template/ChartContainer';
import { useDashboardData } from './hooks/useDashboardData.hook';

import './dashboard.scss';
import { SpinnerLoader } from 'components/Common/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { formatNumber, numberWithDots } from 'utils/utils';
import { InformationNavigation } from 'UI/molecules/InformationNavigation';
import { EMAIL_INFORMATION } from '../../../constants';
import TableFooter from 'UI/organism/TableFooter';
const TABLE_GROUP = 'MCI_A';


export const CEIDashboard = () => {
	const { t } = useTranslation();
	const { setShowHeader } = useProjects();
	const { summary, ranking, loading } = useDashboardData();
	const [emptyData, setEmptyData] = useState(false);

	useEffect(() => {
		setShowHeader(true);
	}, []);

	useEffect(() => {
		if (summary.mci_a === '0' && summary.recycled === '0' && !summary.TU_waste && summary.utility === '0') {
			setEmptyData(true);
		} else {
			setEmptyData(false);
		}
	}, [summary]);

	if (loading) {
		return (
			<div className="m-auto h-100 d-flex justify-content-center align-items-center">
				<SpinnerLoader
					color={'#1C1C30'}
					width={'80px'}
					height={'80px'}
				/>
			</div>
		);
	}

	return (
		emptyData ? <InformationNavigation keyName="upgrade" email={EMAIL_INFORMATION} />
		: <>
			<div className="d-flex flex-wrap">
				<div
					className="col p-0 m-2"
					style={{ minWidth: '200px', height: '188px' }}
				>
					<ChartContainer title={t('cei-dashboard-mci-score')}>
						<div
							className={`d-flex align-items-center text-center metrics m-auto font-sfpro-medium text-black-100 metrics-${summary.mci_a_score} p-0`}
							style={{
								fontSize: '24px',
								width: 'max-content',
								gap: '5px',
							}}
						>
							<span>{summary.mci_a}%</span>
							<div
								className="traceability-status px-2 py-0 h-auto"
								style={{ fontSize: '14px' }}
							>
								{summary.mci_a_score}
							</div>
						</div>
					</ChartContainer>
				</div>
				<div
					className="col p-0 m-2"
					style={{ minWidth: '200px', height: '188px' }}
				>
					<ChartContainer title={t('cei-dashboard-recycled-content')}>
						<div
							className="d-flex m-auto font-sfpro-medium text-black-100"
							style={{ fontSize: '24px' }}
						>
							{summary.recycled}%
						</div>
					</ChartContainer>
				</div>
				<div
					className="col p-0 m-2"
					style={{ minWidth: '200px', height: '188px' }}
				>
					<ChartContainer
						title={t('cei-dashboard-total-unrecoverable-waste')}
					>
						<div
							className="d-flex m-auto font-sfpro-medium text-black-100"
							style={{ fontSize: '24px' }}
						>
							{numberWithDots(formatNumber(summary.TU_waste))}
						</div>
					</ChartContainer>
				</div>
				<div
					className="col p-0 m-2"
					style={{ minWidth: '200px', height: '188px' }}
				>
					<ChartContainer title={t('cei-dashboard-usage-potential')}>
						<div
							className="d-flex m-auto font-sfpro-medium text-black-100"
							style={{ fontSize: '24px' }}
						>
							{summary.utility}%
						</div>
					</ChartContainer>
				</div>
			</div>
			<div
				className="d-flex col p-0 m-2 w-100"
				style={{
					minWidth: '550px',
					height: '313px',
				}}
			>
				<ChartContainer
					title={'Overall circularity performance ranking'}
					subtitle={
						'Top three articles with the highest and lowest performance based on the MCI score'
					}
				>
					<div
						className="d-flex flex-row align-items-center justify-content-between"
						style={{ gap: '40px' }}
					>
						<div>
							<HeaderTable
								group={TABLE_GROUP}
								bestArticles
								traceability
							/>
							<Table
								group={TABLE_GROUP}
								articleGroupName="bestArticles"
								articleGroup={ranking.best}
								score="MCI_A_score"
							/>
						</div>
						<div>
							<HeaderTable
								group={TABLE_GROUP}
								worstArticles
								traceability
							/>
							<Table
								group={TABLE_GROUP}
								articleGroupName="worstArticles"
								articleGroup={ranking.worst}
								score="MCI_A_score"
							/>
						</div>
					</div>
				</ChartContainer>
			</div>
			<TableFooter />
		</>
	);
};
