import React, { ReactNode, useEffect, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { Auth } from '../../../services/storage/Auth';
import { Language } from '../../../services/storage/Language';
import AppPaths from 'permissions/roles/AppPaths';
import Modal from 'components/Common/Modal/Modal';
import Input from '../../../components/Elements/Input/Input';

import { useTranslation } from 'react-i18next';

import UserHttp from 'services/http/User';
import ErrorException from 'services/exception/ErrorException';
import Notification from '../../../components/Elements/Notification';
import RegexLibrary from '../../../utils/Regex';
import { useAccount } from 'contexts/accountContext'
import HandlePasswordVisibility, { isEmptyValue, VisibilityPassword } from 'UI/atoms/PasswordVisibility';
import { useProjects } from 'contexts/projectsContext';

const User = new UserHttp();

function EditProfileLogout({ children, top }: { children: ReactNode, top?: boolean}) {

    const { user, setUser } = useAccount();

    const { t } = useTranslation();
    const [showEditProfileModal, setShowEditProfileModal] = useState('');
    const [showChangePasswordModal, setShowChangePasswordModal] = useState('');

    const [userEdited, setUserEdited] = useState({});
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [disabledEditProfile, setDisabledEditProfile] = useState(true);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');

    const [isErrorOldPasswordInput, setIsErrorOldPasswordInput] = useState(false)
    const [isErrorNewPasswordInput, setIsErrorNewPasswordInput] = useState(false)
    const [isErrorRepeatPasswordInput, setIsErrorRepeatPasswordInput] = useState(false)

    const [inputTypeEditProfile, setInputTypeEditProfile] = useState<VisibilityPassword>('password')
    const [inputTypeOldPass, setInputTypeOldPass] = useState<VisibilityPassword>('password')
    const [inputTypeNewPass, setInputTypeNewPass] = useState<VisibilityPassword>('password')
    const [inputTypeRepeatPass, setInputTypeRepeatPass] = useState<VisibilityPassword>('password')
    const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();



    useEffect(() => {
        if (userEdited && emailIsValid) setDisabledEditProfile(false)
        else setDisabledEditProfile(true)
    }, [userEdited, emailIsValid]);

    useEffect(() => {
        if (!showChangePasswordModal) {
            setPassword('')
            setNewPassword('')
            setRepeatNewPassword('')
            setErrMsg('')
            setIsErrorOldPasswordInput(false)
            setIsErrorNewPasswordInput(false)
            setIsErrorRepeatPasswordInput(false)
        }
    }, [showChangePasswordModal])

    useEffect(() => {
        if (!showEditProfileModal) {
            setUserEdited({})
            setEmailIsValid(true)
            setDisabledEditProfile(true)
        }
    }, [showEditProfileModal])



    const handleSubmitEditProfileModal = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!emailIsValid) return;
        try {
            const response = await User.updateUser(user.id, userEdited);
            const newUser = response?.data;
            if (setUser && newUser) setUser(newUser)
            setShowEditProfileModal('');
        } catch (err) {
            console.log("ERR", err);
            const error = await new ErrorException(t('errors-form-error'));
            Notification.displayException(error);
            return;
        }
    };

    const [errMsg, setErrMsg] = useState<undefined | string>()

    const isSamePassword = () => newPassword === repeatNewPassword
    const isNotEmptyInputs = () => repeatNewPassword && newPassword && password


    const handleSubmitChangePasswordModal = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        password ? setIsErrorOldPasswordInput(false) : setIsErrorOldPasswordInput(true);
        (!newPassword || !isSamePassword()) ? setIsErrorNewPasswordInput(true) : setIsErrorNewPasswordInput(false);
        (!repeatNewPassword || !isSamePassword()) ? setIsErrorRepeatPasswordInput(true) : setIsErrorRepeatPasswordInput(false)

        !isNotEmptyInputs() && setErrMsg(t('common-notEmpty'))
        isNotEmptyInputs() && !isSamePassword() && setErrMsg(t('error-password-notMatch'))

        try {
            if (isNotEmptyInputs() && isSamePassword()) {
                const passwordResseted = {
                    email: user?.email,
                    password: password,
                    newPassword: newPassword
                }
                await User.changePassword(passwordResseted)
                setShowChangePasswordModal('');
            }
        } catch (err) {
            console.log("ERR", err);
            setErrMsg(t('errors-wrong-credentials'))
            setIsErrorOldPasswordInput(true)
            return;
        }
    }

    const handleInput = (event: any) => {
        const { value, name } = event.target;
        if (name === 'email' && !RegexLibrary.email.test(value)) {
            console.log("regex", RegexLibrary.email.test(value));
            setEmailIsValid(!emailIsValid);
        } else {
            setUserEdited((prevValue: any) => ({
                ...prevValue,
                [name]: value,
            }));
        }
    }

    const handleClickEditPassword = () => {
        setShowEditProfileModal('')
        setShowChangePasswordModal('modal-change-password');
    }

    const handleGoBackEditProfile = () => {
        setShowEditProfileModal('modal-edit-profile')
        setShowChangePasswordModal('');
    }


    const logOut = () => {
        if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
            new Auth().removeSession();
            new Language().removeLanguageBackend();
            window.location.href = AppPaths.login;
        }
    };

    const ErrorMsg = () => <div className='err-msg position-absolute ml-1' style={{ marginTop: '-5px' }}>{errMsg}</div>;

    const editProfile = () =>{
        if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
            setShowEditProfileModal('modal-edit-profile')
        }
    }

    return (

        <div className={`text-overflow-ellipsis ${top ? 'px-4' : 'pl-2 pr-0'}`} style={{ maxWidth: '184px' }}>
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className={`nav-link cursor-pointer pl-0 ${top ? '' : "py-0 pr-0"}`}>
                    { children }
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right font-sfpro-regular w-100" style={{ top: top ? "-90px" : "", right: top ? "" : "0" }}>
                    <DropdownItem onClick={editProfile}>{t('header-edit-profile')}</DropdownItem>
                    <DropdownItem onClick={logOut}>{t('header-logout')}</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>

            <Modal
                modal={showEditProfileModal}
                setModal={setShowEditProfileModal}
                className="page-selector-modal"
                scrollable
                createText={t('common-save')} //boton submit
                handleSubmit={handleSubmitEditProfileModal}
                title={t('app-edit-profile')} //header modal
                //noCancel
                size="md"
                disableButton={disabledEditProfile}
                modalName="modal-edit-profile">
                <div className="modal-container-edit-profile">
                    <div className="col-12">
                        <Input
                            type="text"
                            placeholder={user?.name} //change by User name
                            autoFocus={true}
                            name="name"
                            className="edit-profile-input"
                            onChange={handleInput}
                            labelText={t('app-edit-profile-user-name')}
                            labelClassName="edit-profile-input-title mt-3"
                            icon="iconClassName userName"
                        />
                        <div className="mt-3">
                            <Input
                                type="email"
                                placeholder={user?.email} //change by email
                                autoFocus={false}
                                invalid={!emailIsValid}
                                name="email"
                                className="edit-profile-input"
                                onChange={handleInput}
                                labelText={t('app-edit-profile-email')}
                                labelClassName="edit-profile-input-title mt-3"
                                icon="iconClassName atSign"
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-5 mb-4">
                            <div className='d-flex align-items-center'>
                                <Input
                                    type={inputTypeEditProfile}
                                    placeholder="Old password"
                                    autoFocus={false}
                                    value={'password'}
                                    className="edit-profile-input"
                                    labelText={t('app-edit-profile-password')}
                                    labelClassName="edit-profile-input-title"
                                    icon="iconClassName lock"
                                />
                                <HandlePasswordVisibility inputType={inputTypeEditProfile} setInputType={setInputTypeEditProfile} isEmpty={isEmptyValue("password")} />
                            </div>
                            <div className="mt-4 mr-2 d-flex justify-content-end">
                                <button
                                    className="d-flex justify-content-end cursor-pointer btn btn-ghost"
                                    onClick={handleClickEditPassword}
                                >
                                    <span className='font-sfpro-medium text-dark-100 cursor-pointer'>
                                        {t('common-edit-password')}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* EDIT PASSWORD MODAL */}
            <Modal
                modal={showChangePasswordModal}
                setModal={setShowChangePasswordModal}
                className="page-selector-modal"
                scrollable
                createText={t('common-save')} //boton submit
                handleSubmit={handleSubmitChangePasswordModal}
                title={t('app-change-password')} //header modal
                handleGoBackIcon={handleGoBackEditProfile}
                size="md"
                disableButton={false}
                modalName="modal-change-password">
                <div className="modal-container-edit-profile">
                    <div className="col-12 mt-3 mb-4">
                        <div className='d-flex align-items-center'>
                            <Input
                                type={inputTypeOldPass}
                                placeholder="Old password"
                                autoFocus={true}
                                className={`edit-profile-input`}
                                onChange={(e: any) => setPassword(e.target.value)}
                                labelText={t('app-edit-profile-old-password')}
                                labelClassName="edit-profile-input-title "
                                icon="iconClassName lock"
                                invalid={isErrorOldPasswordInput}
                            />
                            <HandlePasswordVisibility inputType={inputTypeOldPass} setInputType={setInputTypeOldPass} isEmpty={isEmptyValue(password)} />
                        </div>
                        {isErrorOldPasswordInput && <ErrorMsg />}
                        <div className='d-flex align-items-center mt-4'>
                            <Input
                                type={inputTypeNewPass}
                                placeholder="New password"
                                autoFocus={true}
                                className={`edit-profile-input`}
                                onChange={(e: any) => setNewPassword(e.target.value)}
                                labelText={t('app-edit-profile-new-password')}
                                labelClassName="edit-profile-input-title"
                                icon="iconClassName lock"
                                invalid={isErrorNewPasswordInput}
                            />
                            <HandlePasswordVisibility inputType={inputTypeNewPass} setInputType={setInputTypeNewPass} isEmpty={isEmptyValue(newPassword)} />
                        </div>
                        {isErrorNewPasswordInput && <ErrorMsg />}
                        <div className="mt-2 ml-1 text-dark-60 font-12px font-sfpro-regular">{t('password-description')}</div>
                        <div className='d-flex align-items-center mt-3'>
                            <Input
                                type={inputTypeRepeatPass}
                                placeholder="Repeat new password"
                                autoFocus={true}
                                className={`edit-profile-input`}
                                onChange={(e: any) => setRepeatNewPassword(e.target.value)}
                                labelText={t('app-edit-profile-new-password-repeat')}
                                labelClassName="edit-profile-input-title"
                                icon="iconClassName lock"
                                invalid={isErrorRepeatPasswordInput}
                            />
                            <HandlePasswordVisibility inputType={inputTypeRepeatPass} setInputType={setInputTypeRepeatPass} isEmpty={isEmptyValue(repeatNewPassword)} />
                        </div>
                        {isErrorRepeatPasswordInput && <ErrorMsg />}
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default EditProfileLogout