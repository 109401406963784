import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const HelpCenter = ({ header }: { header: boolean }) => {
	const { t }: any = useTranslation();

    return (
        <UncontrolledDropdown>
            <DropdownToggle tag="a" className={`nav-link cursor-pointer pl-0 ${ header ? "py-0 px-2" : "" }`}>
                <div className="d-flex flex-row align-items-center ">
                    { !header && <span className="icon-icon-help-circle font-20px"/> }
                    <div className="font-sfpro-regular text-overflow-ellipsis overflow-hidden font-14px ml-2">Help { !header && "Center" }</div>
                </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right font-sfpro-regular w-100" style={{top: !header ? "-170px" : "" }}>
                <a className="text-overflow-ellipsis overflow-hidden" target="_blank" rel="noopener noreferrer" href="https://bcome.biz/help-center/" style={{textDecoration: 'none'}}>
                    <DropdownItem className='d-flex align-items-center'>
                        <span>{t('help-center-getting-started')}</span>
                        <span className="icon-icon-new-window ml-2"></span>
                    </DropdownItem>
                </a>
                <a className="text-overflow-ellipsis overflow-hidden" target="_blank" rel="noopener noreferrer" href="https://bcome.biz/help-center/tutorials/" style={{textDecoration: 'none'}}>
                    <DropdownItem className='d-flex align-items-center'>
                        <span>{t('help-center-tutorial')}</span>
                        <span className="icon-icon-new-window ml-2"></span>
                    </DropdownItem>
                </a>
                <a className="text-overflow-ellipsis overflow-hidden" target="_blank" rel="noopener noreferrer" href="https://bcome.biz/help-center/getting-started/product-methodologies/" style={{textDecoration: 'none'}}>
                    <DropdownItem className='d-flex align-items-center'>
                        <span>{t('help-center-methodologies')}</span>
                        <span className="icon-icon-new-window ml-2"></span>
                    </DropdownItem>
                </a>
                <a className="text-overflow-ellipsis overflow-hidden" target="_blank" rel="noopener noreferrer" href="https://bcome.biz/help-center/getting-started/versioning-summary/" style={{textDecoration: 'none'}}>
                    <DropdownItem className='d-flex align-items-center'>
                        <span>{t('help-center-versions')}</span>
                        <span className="icon-icon-new-window ml-2"></span>
                    </DropdownItem>
                </a>

                <div className="my-1" style={{borderTop: "0.5px solid #D6D6D7", margin: "0 .7rem"}} />

                <a className="text-overflow-ellipsis overflow-hidden" target="_blank" rel="noopener noreferrer" href="https://portal.productboard.com/bcome/1-what-s-new/tabs/4-planned" style={{textDecoration: 'none'}}>
                    <DropdownItem className='d-flex align-items-center'>
                        <span>{t('help-center-next')}</span>
                        <span className="icon-icon-new-window ml-2"></span>
                    </DropdownItem>
                </a>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}
export default HelpCenter;


