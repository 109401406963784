import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardHttp from 'services/http/Dashboards';
import ChartContainer from 'UI/Template/ChartContainer';
import { EEquivalencesKeyNames } from 'UI/Template/Table/types/TableEnums';
import LCStagePerImpactIndicatorChart from './Charts/LCStagePerImpact';
import { useProjects } from 'contexts/projectsContext';
import LCASummary from './Charts/LCASummary';
import StagesByIndicatorChart from './Charts/StagesByIndicator';
import ProjectSummary from './Charts/ProjectSummary';
import CertificatesChart from './Charts/Certificates';
import RankingDashboard from './Charts/RankingDashboard';
import { InformationNavigation } from 'UI/molecules/InformationNavigation';
import ComparationByIndustryChart from './Charts/ComparationByIndustry';
import { useMenuState } from 'hooks/useMenuState.hook';
import { SpinnerLoader } from 'components/Common/Loader/Loader';
import { useReferenceType } from 'hooks/useReferenceType.hook';
import { EMAIL_INFORMATION } from '../../../constants';
import TableFooter from '../TableFooter';

interface StagesPerIndicatorsData {
	name: string;
	rm: number;
	mp: number;
	wp: number;
	manuf?: number;
	trans: number;
	pack: number;
}

interface StagesData {
	name: string;
	data: number;
}

const MetricsDashboard = (props: any) => {
	const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();
	const [emptyValue, setEmtyValue] = useState(false);

	const Dashboard = new DashboardHttp();
	const { projectSelected, showHeader, setShowHeader } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);
	const projectId = projectSelected?.id;

	const [stagesPerIndicatorsData, setStagesPerIndicatorsData] = useState<
		StagesPerIndicatorsData[]
	>([]);
	const [stagesByWaterData, setStagesByWaterData] = useState<StagesData[]>(
		[]
	);
	const [stagesByClimateChangeData, setStagesByClimateChangeData] = useState<
		StagesData[]
	>([]);
	const [stagesByEutroData, setStagesByEutroData] = useState<StagesData[]>(
		[]
	);
	const [stagesByFossilFuelsData, setStagesByFossilFuelsData] = useState<
		StagesData[]
	>([]);
	const [lcaData, setLCAData]: any[] = useState();

	const [projectOrigins, setProjectOrigins]: any = useState();

	const validateManufacture = (values: StagesData[]) =>
		validateReferenceType
			? values.filter((x) => x.name !== 'manuf')
			: values;

	const getProjectOrigin = () => {
		const request = Dashboard.projectOrigins(projectId);
		request
			.then((res) => {
				setProjectOrigins(res.data);
			})
			.catch((err) => console.log({ err }))
			.finally(() => setIsLoading(false));
	};

	const getDashboard = () => {
		const request = Dashboard.LCStagePerImpactIndicatorChart(projectId);
		request
			.then((res) => {
				const data = res.data as StagesPerIndicatorsData[];
				const stages = data.map((x) => {
					if (validateReferenceType) {
						const { manuf, ...rest } = x;
						x = rest;
					}

					return x;
				});
				setStagesPerIndicatorsData(stages);
				data && data.length > 0 && setShowHeader(true);
			})
			.catch((err) => console.log({ err }));
	};

	const getStagebyWaterDashboard = () => {
		const request = Dashboard.stagesByIndicator('Water', projectId);
		request
			.then((res) => {
				const data = res.data;
				setStagesByWaterData(validateManufacture(data));
			})
			.catch((err) => console.log({ err }));
	};

	const getStagebyClimateChangeDashboard = () => {
		const request = Dashboard.stagesByIndicator('ClimateChange', projectId);
		request
			.then((res) => {
				const data = res.data;
				setStagesByClimateChangeData(validateManufacture(data));
			})
			.catch((err) => console.log({ err }));
	};

	const getStagebyEutroDashboard = () => {
		const request = Dashboard.stagesByIndicator(
			'Eutrophication',
			projectId
		);
		request
			.then((res) => {
				const data = res.data;

				setStagesByEutroData(validateManufacture(data));
			})
			.catch((err) => console.log({ err }));
	};

	const getStagebyFossilFuelsDashboard = () => {
		const request = Dashboard.stagesByIndicator('FossilFuels', projectId);
		request
			.then((res) => {
				const data = res.data;
				setStagesByFossilFuelsData(validateManufacture(data));
			})
			.catch((err) => console.log({ err }));
	};

	interface equivalences {
		waterKeyName: string;
		climateChangeKeyName: string;
		eutroKeyName: string;
		fossilFuelsKeyName: string;
		waterSavingsKeyName: string;
		climateChangeSavingsKeyName: string;
		eutroSavingsKeyName: string;
		fossilFuelsSavingsKeyName: string;
	}

	const equivalences: equivalences = {
		waterKeyName: EEquivalencesKeyNames.olympicPools,
		climateChangeKeyName: EEquivalencesKeyNames.electricity,
		eutroKeyName: EEquivalencesKeyNames.detergent,
		fossilFuelsKeyName: EEquivalencesKeyNames.planeTravelling,
		waterSavingsKeyName: EEquivalencesKeyNames.olympicPools,
		climateChangeSavingsKeyName: EEquivalencesKeyNames.electricity,
		eutroSavingsKeyName: EEquivalencesKeyNames.detergent,
		fossilFuelsSavingsKeyName: EEquivalencesKeyNames.planeTravelling,
	};

	const getLCADashboard = () => {
		const request = Dashboard.lca(projectId, equivalences);
		request
			.then((res) => {
				const data = res.data;

				setLCAData(data);

				const values = Object.values(data);
				if (values.filter((x) => x === null).length >= 10 ) {
					setEmtyValue(true);
				} else {
					setEmtyValue(false);
				}

			})
			.catch((err) => console.log({ err }));
	};

	useEffect(() => {
		if (projectId) {
			getDashboard();
			getStagebyWaterDashboard();
			getStagebyClimateChangeDashboard();
			getStagebyEutroDashboard();
			getStagebyFossilFuelsDashboard();
			getLCADashboard();
			getProjectOrigin();
		} else {
			setIsLoading(false);
		}
	}, [projectId]);

	const labelCopy = (value: any) => {
		return t(`dash-${value}`);
	};

	const labelShortCopy = (value: string) => {
		return t(`dash-short-${value}`);
	};

	const units = {
		water: 'm3eq',
		climateChange: 'kgCO2eq',
		eutro: 'gPO4eq',
		fossilFuels: 'MJ',
	};

	if (isLoading) {
		return (
			<div className="m-auto h-100 custom-spinner">
				<SpinnerLoader
					color={'#1C1C30'}
					width={'60px'}
					height={'60px'}
				/>
			</div>
		);
	}

	return (
		emptyValue ? <InformationNavigation keyName='upgrade' email={EMAIL_INFORMATION} />
		: <>
			<div
				className="d-flex w-100 flex-column flex-grow-1 h-100 overflow-auto pb-2"
				style={{ marginTop: '-5px' }}
			>
				<div id="metrics-dashboard">
					<div className="d-flex flex-wrap">
						{/* LCA Overview by indicator */}
						{lcaData && (
							<LCASummary
								data={lcaData}
								equivalences={equivalences}
							/>
						)}
					</div>

					<div className="d-flex flex-wrap">
						{/* LCA Overview by stages */}
						<div
							className="col p-0 m-2"
							style={{ minWidth: '450px' }}
						>
							<ChartContainer
								title={t('dash-title-LCStagePerImpact')}
								subtitle={t('dash-subtitle-LCStagePerImpact')}
							>
								<LCStagePerImpactIndicatorChart
									labelCopy={labelCopy}
									data={stagesPerIndicatorsData}
									validateReferenceType={
										validateReferenceType
									}
								/>
							</ChartContainer>
						</div>
						{/* Comparation with industry */}
						<div
							className="col p-0 m-2"
							style={{ minWidth: '450px' }}
						>
							<ChartContainer
								title={t('graph-comparation-lca-dash-title')}
								subtitle={t(
									'graph-comparation-lca-dash-subtitle'
								)}
								ranking
							>
								<ComparationByIndustryChart
									data={lcaData}
									equivalences={equivalences}
								/>
							</ChartContainer>
						</div>
					</div>

					{/* Indicators by stages */}
					<div className="d-flex w-100 flex-wrap justify-content-around">
						<div className="d-flex col flex-wrap px-0">
							{stagesByWaterData && (
								<div
									className="col p-0 m-2"
									style={{ minWidth: '450px' }}
								>
									<ChartContainer
										title={t(
											'benchmark-dashboard-water-scarcity-impact'
										)}
									>
										<div
											className="d-flex overflow-auto justify-content-center"
											style={{ height: '250px' }}
										>
											<StagesByIndicatorChart
												unit={units.water}
												className="stagesByWater"
												data={stagesByWaterData}
												color={'#001D6C'}
												labelShortCopy={labelShortCopy}
												labelCopy={labelCopy}
											/>
										</div>
									</ChartContainer>
								</div>
							)}
							{stagesByClimateChangeData && (
								<div
									className="col p-0 m-2"
									style={{ minWidth: '450px' }}
								>
									<ChartContainer
										title={t(
											'benchmark-dashboard-global-warming-impact'
										)}
									>
										<div
											className="d-flex overflow-auto justify-content-center"
											style={{ height: '250px' }}
										>
											<StagesByIndicatorChart
												unit={units.climateChange}
												className="stagesByClimateChange"
												data={stagesByClimateChangeData}
												color={'#001D6C'}
												labelShortCopy={labelShortCopy}
												labelCopy={labelCopy}
											/>
										</div>
									</ChartContainer>
								</div>
							)}
						</div>
						<div className="d-flex col flex-wrap px-0">
							{stagesByEutroData && (
								<div
									className="col p-0 m-2"
									style={{ minWidth: '450px' }}
								>
									<ChartContainer
										title={t(
											'benchmark-dashboard-eutrophication-impact'
										)}
									>
										<div
											className="d-flex overflow-auto justify-content-center"
											style={{ height: '250px' }}
										>
											<StagesByIndicatorChart
												unit={units.eutro}
												className="stagesByEutro"
												data={stagesByEutroData}
												color={'#001D6C'}
												labelShortCopy={labelShortCopy}
												labelCopy={labelCopy}
											/>
										</div>
									</ChartContainer>
								</div>
							)}
							{stagesByFossilFuelsData && (
								<div
									className="col p-0 m-2"
									style={{ minWidth: '450px' }}
								>
									<ChartContainer
										title={t(
											'benchmark-dashboard-abiotic-depletion-impact'
										)}
									>
										<div
											className="d-flex overflow-auto justify-content-center"
											style={{ height: '250px' }}
										>
											<StagesByIndicatorChart
												unit={units.fossilFuels}
												className="stagesByFossilFuels"
												data={stagesByFossilFuelsData}
												color={'#001D6C'}
												labelShortCopy={labelShortCopy}
												labelCopy={labelCopy}
											/>
										</div>
									</ChartContainer>
								</div>
							)}
						</div>
					</div>

					{/* LCA best articles ranking */}
					<div className="d-flex p-0 m-2">
						<ChartContainer
							title={t('dash-title-lca-ranking')}
							subtitle={t('dash-subtitle-lca-ranking')}
							ranking
							footer={t('dash-footer-ranking')}
						>
							<RankingDashboard />
						</ChartContainer>
					</div>
				</div>
			<TableFooter />
			</div>
		</>
	);
};

export default MetricsDashboard;
