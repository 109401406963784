import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAccount } from 'contexts/accountContext';
import { SidebarItem } from './SidebarComponents';
import AppPaths from 'permissions/roles/AppPaths';
import EditProfileLogout from 'UI/molecules/EditProfileLogout';
import { menuItem } from './TSidebar';
import HelpCenter from 'UI/Template/HelpCenter/Index';
import { useProjects } from 'contexts/projectsContext';

const Sidebar = (props: any) => {
	const { user, setUser, account } = useAccount();
	const { menu, isSideBar } = props;
	const { openModalSaveChanges, setOpenModalSaveChanges, setAccessType } = useProjects();

	React.useEffect(()=> {
		setAccessType(menu);
	},[menu])

	const stopRedirection = (event:any) => {
		if( openModalSaveChanges.customView ){
			event.preventDefault();
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}
	}


	const itemsByGroupObj: any = {}

	menu.forEach((item: any) => {
		if (!Object.keys(itemsByGroupObj).includes(item.group.toString())) itemsByGroupObj[item.group] = [item]
		else itemsByGroupObj[item.group].push(item)
	})
	const itemsByGroupArr: any = Object.values(itemsByGroupObj)

	const Line = ({ my = 2 }: any) => (
		<div className={`my-${my} mx-3`} style={{ borderTop: '0.5px solid #ACACB2' }}></div>
	)

	return (
		<aside className={`aside-container ${isSideBar ? 'fadeInLeft' : 'fadeInRight'}`} style={{ width: '100%' }}>
			{/* START Sidebar (left) */}
			<div className="aside-inner pb-2" style={{ minHeight: '95vh' }}>
				<nav data-sidebar-anyclick-close="" className="sidebar show-scrollbar" style={{ overflow: "visible" }}>
					{/* START sidebar nav */}
					<div className="d-flex flex-column h-100">
						<div className="text-center my-4">
							<Link to={AppPaths.home} onClick={stopRedirection}>
								<img
									className="img-fluid"
									style={{ width: '50%' }}
									src="../img/bcome-logo1.svg"
									alt="logo-header"
								/>
							</Link>
						</div>
						<div className="h-100 d-flex flex-column justify-content-between overflow-auto">
							<ul className="sidebar-nav" style={{ marginBottom: '150px' }}>
								{itemsByGroupArr.map((itemGroup: any, i: number) => (
									<Fragment key={itemGroup + i}>
										{itemGroup.map((item: menuItem, i: number) => {
											return (
												<SidebarItem
													item={item}
													keyValue={item.path + i}
												/>
											)
										}
										)}
										{(i + 1) < itemsByGroupArr.length && <Line />}
									</Fragment>
								)
								)}
							</ul>

							{/* END sidebar nav */}
							<div className="position-fixed mb-3" style={{ backgroundColor: '#F0F0F2', bottom: 0, width: '224px' }}>
								<Line my='2' />
								<div className='align-items-end text-dark-60 hover-dark-100 bg-white-hover-100 rounded-08 px-2 mb-2 mx-3'>
									<HelpCenter header={false} />
								</div>
								<Line my='1' />
								<EditProfileLogout top>
								<div className="d-flex flex-row align-items-center mt-2">
									<img src={`${account?.logoUrl ? account?.logoUrl : '../img/logo_bcome.jpeg'}`} width="40" height="40" style={{ borderRadius: '50%', marginRight: '10px' }} />
									{user && (
										<div>
											<div className="font-sfpro-regular text-dark-60 text-overflow-ellipsis overflow-hidden font-12px" style={{maxWidth: '120px'}}>{user.name}</div>
											<div className="font-sfpro-regular text-dark-60 text-overflow-ellipsis overflow-hidden font-12px" style={{maxWidth: '120px'}}>{user.email}</div>
										</div>
									)}
								</div>
								</EditProfileLogout>
							</div>
						</div>
					</div>
				</nav>
			</div>
			{/* END Sidebar (left) */}
		</aside>
	);
};

export default Sidebar;
