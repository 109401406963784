import React from 'react';
import { useTranslation } from 'react-i18next';

import DashboardHttp from 'services/http/Dashboards';
import '../../Template/Modals/ModalBase/ModalBase.scss';
import { formatValueFunctionalUnit, numberWithDots } from 'utils/utils';
import moment from 'moment';

export const ModalProjectInfo = (props: any) => {
	const { handleClose, isShow, projectData } = props;
	const Dashboard = new DashboardHttp();
	const [projectSummaryData, setProjectSummaryData]: any[] = React.useState();
	const showHideClassName = isShow
	? 'modal-base display-block backdrop-color'
	: 'modal-base display-none';

	const { t } = useTranslation();

	const lastUpdate = projectData?.lastExcelUpload ?? projectData?.updatedAt;
	const momentLastUpdate = moment(lastUpdate).format('MMM D, YYYY')


	const projectSummary = () => {
		const projectId = projectData?.id;
		if (projectId) {
			const request = Dashboard.projectSummary(projectId);
			return request
				.then((res: any) => res.data)
				.catch((err: string) => console.log({ err }));
		}
	};

	React.useEffect(() => {
		let isMounted = true;
		projectSummary()
		?.then((res: any) => isMounted && setProjectSummaryData(res))

		return () => {
			isMounted = false;
		}
	}, [projectData])

	return (
		<div className={showHideClassName}>
			<div className="modal-base-main product-info-size">
				<div className="d-flex justify-content-between align-items-center">
					<span className="font-sfpro-medium text-dark-100 font-24px">
						{t('projects-details-modal-title')}
					</span>
					<span
						className="icon-icon-close font-12px text-dark-60 hover-dark-100"
						onClick={handleClose}
					></span>
				</div>

				<div className="product-info-section pt-2">
					<div className="font-sfpro-medium text-dark-60 font-12px my-2">
						{t('projects-details-modal-summary')}
					</div>
					<div className="font-sfpro-medium text-dark-100 font-14px my-2">
						{t('article-detail-name')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{projectData?.name}
					</div>
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-dark-100 font-14px my-2">
						{t('projects-details-modal-total-references')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{ numberWithDots(projectSummaryData?.amount ?? 0) }
					</div>
				</div>

                <div className="product-info-section py-2">
					<div className="font-sfpro-medium text-dark-100 font-14px my-2">
						{t('bcome-version')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{ projectData?.version }
					</div>
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-dark-100 font-14px my-2">
						{t('functional-unit')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{ projectData?.functionalUnit ? formatValueFunctionalUnit(projectData?.functionalUnit, null) : '--' }
					</div>
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-dark-100 font-14px my-2">
						{t('lastUpdated')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{ momentLastUpdate }
					</div>
				</div>
			</div>
		</div>
	);
};
