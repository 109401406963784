import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useProjects } from 'contexts/projectsContext';
import { useTable } from 'contexts/tableContext';
import { useAccount } from 'contexts/accountContext';
import { useTranslation } from 'react-i18next';
import { SidebarDisplayer } from 'UI/atoms/SidebarDisplayer';
import TableDashboardSelector, {
	TableDashboardSelectorDisabled,
} from 'UI/molecules/TableDashboardSelector';
import FiltersSection, { SelectedTag } from 'UI/molecules/FiltersSection/Index';
import { ScopeSelection } from 'UI/molecules/ScopeSelection/ScopeSelection';
import {
	REFERENCES_TYPE,
	SCOPES_BY_ARTICLE,
	HELP_LINKS,
} from '../../../constants';
import { ModalLateral } from 'UI/organism/ModalLateral/Index';
import { ModalViewAction } from 'UI/organism/ModalViewAction';
import { SelectorColumnsModal } from 'UI/molecules/SelectorColumnsModal/Index';
import { SelectorFiltersModal } from 'UI/molecules/SelectorFiltersModal/Index';
import { Breadcrumb } from 'UI/molecules/Breadcrumb/Index';
import { ScopeModal } from '../ScopeModal/ScopeModal';
import AppPaths from '../../../permissions/roles/AppPaths';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { Bell } from './Bell';
import TableMenu from 'UI/molecules/TableMenu';
import { useMenuState } from 'hooks/useMenuState.hook';
import EditProfileLogout from 'UI/molecules/EditProfileLogout';
import HelpCenter from 'UI/Template/HelpCenter/Index';
import SelectorProject from 'UI/molecules/SelectorProject';

const NAME_BY_PATH = [
	{
		name: 'Data Collection',
		path: '/traceability/data-collection',
		feature: 'data_collection',
	},
	{ name: 'PLM', path: '/traceability/plm', feature: 'plm' },
	{ name: 'Journey Map', path: '/traceability/journey-map', feature: '' },
	{
		name: 'Life Cycle Assessment',
		path: '/metrics/lca/dashboard/',
		feature: 'lca',
	},
	{
		name: 'Life Cycle Assessment',
		path: '/metrics/lca/articles',
		feature: 'lca',
	},
	{ name: 'Benchmark', path: '/metrics/benchmark/articles', feature: '' },
	{ name: 'Circularity', path: '/cei', feature: 'cei' },
	{
		name: 'Eco-score',
		path: '/evaluation/sustainabilityindex/dashboard',
		feature: 'eco_score',
	},
	{
		name: 'Eco-score',
		path: '/evaluation/sustainabilityindex/articles',
		feature: 'eco_score',
	},
	{ name: 'Reporting', path: '/diagnosis/reporting', feature: '' },
	{
		name: 'Labeling',
		path: '/digitization/smartlabels/articles',
		feature: 'labeling',
	},
	{
		name: 'Ecommerce',
		path: '/digitization/ecommerce/integration',
		feature: 'integration',
	},
	{
		name: 'Ecommerce',
		path: '/digitization/ecommerce/customization',
		feature: 'integration',
	},
	{ name: 'Academy', path: '/ecodesign/academy', feature: '' },
	{ name: 'Glossary', path: '/ecodesign/glossary', feature: '' },
	{
		name: 'Stage comparator',
		path: '/ecodesign/calculator',
		feature: 'stage_comparator',
	},
	{ name: 'Library', path: '/library', feature: 'library' },
];

const pathWithTableMenu = ['digitization'];
const LIBRARY = 'library';

const PageHeader = (props: any) => {
	const {
		setOpenColumnSelectorModal,
		isLoading,
		handleCloseColumnSelectorModal,
		modalViewActionSelected,
		openColumnSelectorModal,
		handleCloseFiltersModal,
		openFiltersModal,
		openDetailScopeModal,
		setOpenDetailScopeModal,
		itemSelected,
	} = useTable();
	const { projectSelected, showHeader } = useProjects();
	const [referenceType, setReferenceType] = useState('');
	const { user } = useAccount();
	const { t } = useTranslation();
	const wPath = window.location.pathname;
	const generalProduct = wPath.split('/')[1];
	const product = wPath.split('/')[2];
	const scope = wPath.split('/')[3];

	const [clickNotification, setClickNotification] = useState<boolean>(false);
	const notificationRef = useRef<HTMLDivElement>(null);
	const tooltipId = 'notification-bell';
	const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

	const { setIsSideBar, isSideBar } = props;

	const marginLeft = isSideBar ? '-18px' : '12px';

	const productsWithSelectorProject = [
		'lca',
		'benchmark',
		'sustainabilityindex',
		'smartlabels',
		'plm',
		'data-collection',
		'cei',
		'dashboard',
	];
	const productsWithActiveTableDashboardSelector = [
		'lca',
		'benchmark',
		'sustainabilityindex',
		'plm',
		'cei',
		'data-collection',
	];
	const productsWithDisabledDashboardSelector = [''];

	const productsHeaderTitleWithoutData = [
		'reporting',
		'journey-map',
		'academy',
		'glossary',
		'calculator',
	];

	useEffect(() => {
		if (projectSelected) {
			const copyReferenceType =
				projectSelected.referenceType?.toLowerCase() ?? '--';
			setReferenceType(copyReferenceType);
			if (
				product === 'plm' &&
				REFERENCES_TYPE.includes(copyReferenceType) &&
				scope === 'articles'
			) {
				window.location.href = AppPaths.traceabilityMaterials;
			}
		}
	}, [projectSelected]);

	const handleCallToAction = () =>
		NAME_BY_PATH.find((item) => wPath.includes(item.path)) ?? {
			name: '',
			path: '',
			feature: '',
		};

	const { callToAction } = useCallback(() => {
		return useMenuState(handleCallToAction().feature);
	}, [wPath])();
	const handleScopes = () => {
		if (product === 'plm' && REFERENCES_TYPE.includes(referenceType)) {
			if (SCOPES_BY_ARTICLE[product].includes('articles')) {
				return SCOPES_BY_ARTICLE[product].filter(
					(item: string) => item !== 'articles'
				);
			}
		}
		return SCOPES_BY_ARTICLE[product] ?? null;
	};

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (
				notificationRef.current &&
				!notificationRef.current.contains(event.target as Node)
			) {
				setClickNotification(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleMouseAction = (value: boolean) => setIsTooltipOpen(value);

	const handleToggleColumns = () => {
		setOpenColumnSelectorModal(true);
	};

	const help = HELP_LINKS[`${product}_${scope ?? ''}`] ?? {
		question: '',
		link: '',
	};

	return (
		<div
			id="header"
			style={{ display: generalProduct === '404' ? 'none' : '' }}
		>
			<div className="d-flex flex-row align-items-center w-100">
				<SidebarDisplayer
					setIsSideBar={setIsSideBar}
					isSideBar={isSideBar}
				/>
				{generalProduct === '' && (
					<div className="w-100 d-flex flex-row justify-content-between align-items-center">
						<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px w-100">
							{t('path-dashboard')} {user?.name}!
						</h1>
						<HeaderTop special />
					</div>
				)}
				{callToAction ? (
					<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px">
						{handleCallToAction().name}
					</h1>
				) : (
					<>
						{generalProduct === 'management' && (
							<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px">
								{t('traceability')}
							</h1>
						)}
						{!showHeader && product === 'cei' && (
							<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px w-100">
								{t('cei-header')}
							</h1>
						)}
						{!showHeader && product === 'smartlabels' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('product-selector-smartlabels')}
								</h1>
							</>
						)}
						{!showHeader && product === 'lca' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('product-selector-lca')}
								</h1>
							</>
						)}
						{!showHeader && product === 'benchmark' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('sidebar-nav-lca-benchmark')}
								</h1>
							</>
						)}
						{!showHeader && product === 'sustainabilityindex' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('sustainabilityIndex')}
								</h1>
							</>
						)}

						{product === 'ecommerce' && (
							<div className="d-flex w-100 flex-column align-items-start">
								<HeaderTop />
								<h1
									className={`font-20px font-sfpro-medium text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize ml-3 d-flex align-items-center`}
									style={{ height: '42px' }}
								>
									{t(`${scope}-header`)}
								</h1>
							</div>
						)}

						{generalProduct === LIBRARY && (
							<div className="w-100 d-flex flex-row align-items-center justify-content-between">
								<h1 className={`font-20px font-sfpro-medium text-dark-100 text-capitalize ml-3 d-flex align-items-center w-100 mb-0`}>
									{t(`library`)}
								</h1>
								<HeaderTop />
							</div>
						)}


							{showHeader && productsWithSelectorProject.includes(product) && (
									<div className="d-flex flex-column w-100">
										<HeaderTop />
										<div className="d-flex flex-row align-items-center justify-content-between w-100">
											<div className="d-flex flex-row align-items-center flex-wrap">
												{(generalProduct ===
													'metrics' ||
													generalProduct ===
														'evaluation' ||
													generalProduct ===
														'digitization' ||
													(generalProduct ===
														'traceability' &&
														product !==
															'data-collection')) && (
													<FiltersSection
														isLoading={isLoading}
													/>
												)}
												{product ===
												'data-collection' ? (
													<span
														className={`font-20px font-sfpro-medium text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize ml-3 d-flex align-items-center`}
														style={{
															height: '42px',
														}}
													>
														{t('data-collection')}
													</span>
												) : generalProduct !==
												LIBRARY ? (
													handleScopes() && (
														<>
															<ScopeSelection
																scopes={handleScopes()}
																{...{
																	projectSelected,
																	isLoading,
																}}
															/>
															{['plm', 'lca', 'cei', 'sustainabilityindex'].includes(product) &&
															scope === 'dashboard' && (
																<SelectedTag
																	isLoading={isLoading}
																	wPath={wPath}
																	generalProduct={generalProduct}
																	product={product}
																/>
															)}
														</>
													)
												) : (
													<span
														className={`font-20px font-sfpro-medium text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize ml-3`}
													>
														{t(
															'sidebar-nav-library'
														)}
													</span>
												)}
											</div>
											<div className="mx-4 d-flex align-items-center flex-wrap justify-content-md-end">
												{productsWithActiveTableDashboardSelector.includes(
													product
												) && (
													<TableDashboardSelector
														generalProduct={
															generalProduct
														}
													/>
												)}

												{pathWithTableMenu.includes(
													generalProduct
												) && (
													<TableMenu
														handleToggleColumns={
															handleToggleColumns
														}
													/>
												)}
												{productsWithDisabledDashboardSelector.includes(
													product
												) && (
													<TableDashboardSelectorDisabled />
												)}
											</div>
										</div>
									</div>
								)}
							{generalProduct === '' ? (
								<div
									className={`text-dark-60 text-dark-100-hover p-2 mr-4 ${
										clickNotification
											? 'rounded-08 bg-gray-01'
											: ''
									}`}
									onClick={() =>
										setClickNotification(!clickNotification)
									}
									ref={notificationRef}
								>
									<a
										href="https://bcome.biz/help-center/product-updates/release-notes-2024/"
										target="_blank"
										rel="noopener noreferrer"
										className="text-decoration-none d-flex align-items-center text-dark-100-hover"
										id={tooltipId}
										onMouseEnter={() =>
											handleMouseAction(true)
										}
										onMouseLeave={() =>
											handleMouseAction(false)
										}
									>
										<Bell isTooltipOpen={isTooltipOpen} />
									</a>
									<SimpleTooltip
										tooltipId={tooltipId}
										tooltipOpen={isTooltipOpen}
										text={t('product-updates')}
										styles={{
											maxWidth: '250px',
											textAlign: 'start',
										}}
									/>
								</div>
							) : (
								''
							)}
							{productsHeaderTitleWithoutData.includes(
								product
							) && (
								<div
									className={`d-flex flex-column w-100 align-items-center`}>
									<HeaderTop />
									<h1 className="w-100 pb-0 mb-0 mx-5 pl-3 mb-2 text-dark-100 font-sfpro-medium font-20px">
										{t(`${product}-header`)}
									</h1>
								</div>
							)}
					</>
				)}
			</div>
			{openFiltersModal && (
				<ModalLateral handleClose={handleCloseFiltersModal}>
					<SelectorFiltersModal
						title={'Filters'}
						handleClose={handleCloseFiltersModal}
					/>
				</ModalLateral>
			)}
			{openColumnSelectorModal && (
				<ModalLateral handleClose={handleCloseColumnSelectorModal}>
					<SelectorColumnsModal
						title={'Columns'}
						handleClose={handleCloseColumnSelectorModal}
					/>
				</ModalLateral>
			)}
			{modalViewActionSelected && <ModalViewAction />}
			{openDetailScopeModal && (
				<ModalLateral
					handleClose={() => setOpenDetailScopeModal(false)}
					style={{
						minWidth: '580px',
						maxWidth: '580px',
					}}
				>
					<ScopeModal
						scope={scope.slice(0, scope.length - 1)}
						data={itemSelected}
						handleCloseModal={() => setOpenDetailScopeModal(false)}
					/>
				</ModalLateral>
			)}
		</div>
	);
};

const HeaderTop = ({ special }: { special?: boolean}) => {
	const padding = special ? '16px 0 24px' : '16px 24px 24px';
	return (
		<div
			className="w-100 d-flex justify-content-between align-items-center"
			style={{ padding: padding }}
		>
			<Breadcrumb />
			<ul className="nav">
				<li className="nav-item">
					<HelpCenter header={true} />
					{/* <a
						className="text-dark-100 nav-link py-0 px-2"
						style={{ color: '#1C1C30' }}
						target="__blank"
						href="https://bcome.biz/es/help-center/"
					>
						Help
					</a> */}
				</li>
				<li className="nav-item">
					<a
						className="text-dark-100 nav-link py-0 px-2"
						style={{ color: '#1C1C30' }}
						href="mailto:info@bcome.biz"
					>
						Contact
					</a>
				</li>
				<li className="nav-item">
					<EditProfileLogout>
						Account
					</EditProfileLogout>
				</li>
			</ul>
		</div>
	);
};

export default PageHeader;
